import React from "react";
import Timeline from "./Timeline/Timeline";
import { Col } from "react-bootstrap";
import "./Experience.scss";

const Experience = () => {
  return (
    <Col id="experience">
      <h1 className="text-center">My Experience</h1>
      <Timeline />
    </Col>
  );
};

export default Experience;
