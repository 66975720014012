import React from "react";
import "./Timeline.scss";

class Timeline extends React.Component {
  state = {
    experiences: [
      {
        title: "Web UI Developer",
        description:
          "Working with Next.js / Styled Components / Atomic Design / Storybook",
        date: "January 2021"
      },
      {
        title: "Software Engineer Ssr",
        description:
          "I developed a Headless CMS using: React / Ant Design / Atomic Design / Redux",
        date: "June 2020"
      },
      {
        title: "Full Stack Developer",
        description:
          "I managed tools like AWS, Serverless, React, Firebase, DynamoDB etc",
        date: "February 2020"
      }
    ]
  };
  render() {
    return (
      <div className="timeline">
        <ul>
          {this.state.experiences.map((exp, index) => {
            return (
              <li key={index}>
                <div className="content">
                  <h3>{exp.title}</h3>
                  <p>{exp.description}</p>
                </div>
                <div className="time">
                  <h4>{exp.date}</h4>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Timeline;
