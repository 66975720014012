import React from "react";
import { FaHtml5, FaAppStoreIos, FaDrawPolygon } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import { Col, Row, Card } from "react-bootstrap";
import "./Services.scss";

class Services extends React.Component {
  state = {
    services: [
      {
        name: "Software Development",
        icon: <FaHtml5 />,
        description: "Need a custom software? I can help you right now!"
      },
      {
        name: "Website Design",
        icon: <MdWeb />,
        description:
          "Looking for a new look for your site? Let me give you a hand."
      },
      {
        name: "App Development",
        icon: <FaAppStoreIos />,
        description: "Let's take your project from vision to success."
      },
      {
        name: "Graph Design",
        icon: <FaDrawPolygon />,
        description: "Looking for the best design? I surely have what you need."
      }
    ]
  };
  render() {
    return (
      <Col md={6} className="text-center" id="services">
        <h1>Services</h1>
        <p>
          We have extensive experience and in-depth knowledge. I provide
          tangible analytics, decision support and decision automation within
          various businesses.
        </p>
        <Row className="servicesBox">
          {this.state.services.map((service, index) => {
            return (
              <Card key={index}>
                <Card.Body>
                  {service.icon}
                  <Card.Title>{service.name}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            );
          })}
        </Row>
      </Col>
    );
  }
}

export default Services;
