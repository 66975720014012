import React from "react";
import "./Banner.scss";
import Cogs from "./Cogs/Cogs";
import { FaGithub, FaLinkedinIn, FaTwitter } from "react-icons/fa";

import { Container, Row, Col } from "react-bootstrap";

const Banner = () => {
  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{ marginTop: "-10%" }}
      >
        <path
          fill="#ededed"
          fillOpacity="1"
          d="M0,256L1440,288L1440,320L0,320Z"
        ></path>
      </svg>
      <div className="section" id="banner">
        <Container className="pl-5 pr-5">
          <Row>
            <Col className="text-center border-right">
              <h1>Contact me!</h1>
              <p>Let's have a talk, looking forward to hear from you!</p>
              <ul>
                <li>
                  <a
                    href="https://github.com/mikesosa?tab=repositories"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGithub size="2rem" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/mike-sosa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn size="2rem" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/micael_sosa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size="2rem" />
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="text-center">
              <a
                href="mailto:michael.sosa@holbertonschool.com?subject=Question"
                target="_blank"
                rel="noopener noreferrer"
                type="button"
                id="getInTouch"
              >
                Get in Touch!
              </a>
              <Cogs />
            </Col>
          </Row>
        </Container>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ededed"
          fillOpacity="1"
          d="M0,64L1440,32L1440,0L0,0Z"
        ></path>
      </svg>
    </React.Fragment>
  );
};

export default Banner;
