import React from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedinIn,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

const NavSocialLinks = () => {
  return (
    <Nav>
      <ul className="list-inline social-buttons text-center">
        <li className="list-inline-item">
          <a
            href="https://twitter.com/micael_sosa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://github.com/mikesosa?tab=repositories"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://www.linkedin.com/in/mike-sosa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </li>
      </ul>
    </Nav>
  );
};

export default NavSocialLinks;
