import React from "react";
import "./App.scss";
import MyNavbar from "./components/MyNavbar/MyNavbar";
import Masthead from "./components/Masthead/Masthead";
import Portfolio from "./components/Portfolio/Portfolio";
import MyFooter from "./components/MyFooter/MyFooter";
import About from "./components/About/About";
import Banner from "./components/Banner/Banner";

function App() {
  return (
    <div className="App">
      <MyNavbar />
      <Masthead />
      <About />
      <Banner />
      <Portfolio />
      <MyFooter />
    </div>
  );
}

export default App;
