import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";

const MyFooter = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center text-center">
          <Col>
            <span className="copyright">Created by me &copy; 2020</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MyFooter;
