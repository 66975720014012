import React from "react";
import logo from "../../img/logo.png";
import "./MyNavbar.scss";
import NavSocialLinks from "./NavSocialLinks/NavSocialLinks";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import { Link } from "react-scroll";
import { CSSTransition } from "react-transition-group";

class MyNavbar extends React.Component {
  state = {
    isTop: true
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop: isTop });
      }
    });
  }

  render() {
    return (
      <CSSTransition>
        <Navbar
          expand="lg"
          fixed="top"
          bg={this.state.isTop ? "light" : "dark"}
          variant={this.state.isTop ? "light" : "dark"}
        >
          <Container>
            <Link
              activeClass="active"
              to="page-top"
              spy={true}
              smooth={true}
              offset={-90}
              duration={800}
            >
              <Navbar.Brand>
                <Image src={logo} rounded className="navbar-brand" />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto text-center">
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={800}
                  className="nav-link"
                >
                  About
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={800}
                  className="nav-link"
                >
                  Services
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={800}
                  className="nav-link"
                >
                  Portfolio
                </Link>
                <Link
                  activeClass="active"
                  to="banner"
                  spy={true}
                  smooth={true}
                  offset={-200}
                  duration={800}
                  className="nav-link"
                >
                  Contact
                </Link>
              </Nav>
              <NavSocialLinks />
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </CSSTransition>
    );
  }
}

export default MyNavbar;
