import React from "react";
import Peter from "../../../img/peter.png";
import Calculator from "../../../img/calculator.png";
import Signup from "../../../img/signup.gif";
import Asteroids from "../../../img/asteroids.gif";
import Maze from "../../../img/maze.gif";
import Predictions from "../../../img/text-predictions.gif";
import Scrolling from "../../../img/scrolling.gif";
import Drum from "../../../img/drum.gif";
import "./Projects.scss";
import { Row, Col, Image } from "react-bootstrap";

class Projects extends React.Component {
  state = {
    projects: [
      {
        name: "Infinite scrolling",
        img: Scrolling,
        description: "React JS",
        link: "https://mikesosa.github.io/nvc-test"
      },
      {
        name: "Peter Answers",
        img: Peter,
        description: "Javascript - JQuery",
        link: "https://mikesosa.github.io/PeterAnswers/"
      },
      {
        name: "Text Prediction",
        img: Predictions,
        description: "Javascript - Regexp",
        link: "https://mikesosa.github.io/CountrySuggestions/"
      },
      {
        name: "Sign Up Form",
        img: Signup,
        description: "Javascript - Regexp",
        link: "https://mikesosa.github.io/RegistrationForm/"
      },
      {
        name: "Calculador",
        img: Calculator,
        description: "VueJs - CSS",
        link: "https://mikesosa.github.io/CalculatorVuejs/"
      },
      {
        name: "Asteroids Game",
        img: Asteroids,
        description: "Python3 - Pygame",
        link: "https://github.com/mikesosa/Asteroids-Game"
      },
      {
        name: "Backtracking Maze",
        img: Maze,
        description: "Javascript",
        link: "https://mikesosa.github.io/MazeGame/"
      },
      {
        name: "Drum bot",
        img: Drum,
        description: "Javascript",
        link: " https://mikesosa.github.io/DrumBot/"
      }
    ]
  };
  render() {
    return (
      <Col md={6}>
        <Row>
          <Col lg={12} className="text-center">
            <h1>Portfolio</h1>
            <p>
              Here are a few old design projects (I need to update this). Want
              to see more?{" "}
              <a
                href="mailto:michael.sosa@holbertonschool.com?subject=Question"
                className="font-weight-normal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Email me
              </a>
              <span> or </span>
              <a
                href="https://github.com/mikesosa?tab=repositories"
                className="font-weight-normal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check out my GitHub
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          {this.state.projects.map((project, index) => {
            return (
              <Col md={4} sm={6} key={index} className="project">
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={project.img} fluid />
                </a>
                <div className="caption">
                  <h4>{project.name}</h4>
                  <p>{project.description}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
    );
  }
}

export default Projects;
