import React from "react";
import "./Skills.scss";

class Skills extends React.Component {
  state = {
    languages: [
      { name: "html & css", percent: "80" },
      { name: "javascript", percent: "75" },
      { name: "react.js", percent: "65" },
      { name: "AWS", percent: "63" }
    ]
  };

  getPercentage(p) {
    return 250 - (250 * p) / 100;
  }
  render() {
    return (
      <div id="skillsGraphs">
        {this.state.languages.map((lang, index) => {
          return (
            <React.Fragment>
              <div className="card" key={index}>
                <div className="box">
                  <div className="percent">
                    <svg>
                      <circle cx="40" cy="40" r="40"></circle>
                      <circle
                        cx="40"
                        cy="40"
                        r="40"
                        style={{
                          strokeDashoffset: this.getPercentage(lang.percent),
                          animation: "dash 3s ease-in-out forwards"
                        }}
                      ></circle>
                    </svg>
                    <div className="number">
                      <h2>
                        {lang.percent}
                        <span>%</span>
                      </h2>
                    </div>
                  </div>
                  <h2 className="text">{lang.name}</h2>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default Skills;
