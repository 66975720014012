import React from "react";
import "./Portfolio.scss";
import Services from "./Services/Services";
import Projects from "./Projects/Projects";
import { Container, Row } from "react-bootstrap";

class Portfolio extends React.Component {
  render() {
    return (
      <section id="portfolio">
        <Container>
          <Row>
            <Services />
            <Projects />
          </Row>
        </Container>
      </section>
    );
  }
}

export default Portfolio;
