import React from "react";
import "./ProgressSkills.scss";
import { Col } from "react-bootstrap";

class ProgressSkills extends React.Component {
  state = {
    skills: [
      { name: "Teamwork", percent: "85" },
      { name: "Problem Solving", percent: "75" },
      { name: "Communication", percent: "90" }
    ]
  };

  render() {
    return (
      <Col className="progressSkills">
        {this.state.skills.map((skill, index) => {
          return (
            <div key={index}>
              <h4>{skill.name}</h4>
              <div className="animation-bar-1">
                <span
                  data-label="Loading"
                  style={{
                    width: `${skill.percent}%`,
                    animation: "dashSkill 2s ease-in-out"
                  }}
                ></span>
              </div>
            </div>
          );
        })}
      </Col>
    );
  }
}

export default ProgressSkills;
