import React from "react";
import "./About.scss";
import { Col, Container, Row } from "react-bootstrap";
import Skills from "./Skills/Skills";
import ProgressSkills from "./ProgressSkills/ProgressSkills";
import Experience from "./Experience/Experience";

const About = () => {
  return (
    <section id="about">
      <Container>
        <Row>
          <Col md={6}>
            <Row>
              <Col className="text-center">
                <h1>Professional Skills</h1>
                <p className="mt-3 mb-3">
                  Skills can be taught, personality is inherent. I prefer to
                  keep learning, continue challenging myself, and do interesting
                  things that matter.
                </p>
                <Skills />
                <div>
                  <p className="mt-3 mb-3">
                    I'm quietly confident, naturally curious, and perpetually
                    working on improving my skills one problem at a time.
                  </p>
                </div>
                <ProgressSkills />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Experience />
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
