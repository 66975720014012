import React from "react";
import "./Masthead.scss";
import Cogs from "./Cogs/Cogs";
import mike from "../../img/mike2.png"; // Tell Webpack this JS file uses this image
import { CSSTransition } from "react-transition-group";
import { Row, Container, Image } from "react-bootstrap";
import { Link } from "react-scroll";

class Masthead extends React.Component {
  state = {
    animateImage: true
  };
  render() {
    return (
      <React.Fragment>
        <div className="masthead" id="page-top">
          <Container>
            <Cogs />
            <Row>
              <div className="intro-text text-left col-md-8">
                <div className="intro-lead-in">Welcome I'm</div>
                <div className="intro-heading text-uppercase css-typing">
                  <h1>Michael Sosa</h1>
                </div>
                <div className="subintro-lead-in">
                  Full Stack Software Developer
                </div>
                <p className="mb-5 text-justify">
                  Since beginning my journey as a freelance Developer nearly 3
                  years ago, I've done remote work for agencies and collaborated
                  with talented people to create digital products for both
                  business and consumer use.
                </p>
                <Link
                  activeClass="active"
                  to="portfolio"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={800}
                  className="btn btn-primary btn-lg text-uppercase mr-2 text-white"
                >
                  My Work
                </Link>
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={800}
                  className="btn btn-primary btn-lg text-uppercase ml-2 text-white"
                >
                  Hire Me!
                </Link>
              </div>
              {/* <img alt="MikeSosa" src="../../img/mike.png" /> */}
              <CSSTransition
                in={this.state.animateImage}
                appear={true}
                timeout={9000}
                classNames="fade"
              >
                <Image className="intro-img" src={mike} />
              </CSSTransition>
            </Row>
          </Container>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
            <path
              fill="#fff"
              fillOpacity="1"
              d="M0,96L360,96L720,128L1080,64L1440,128L1440,320L1080,320L720,320L360,320L0,320Z"
            ></path>
          </svg>
        </div>
      </React.Fragment>
    );
  }
}

export default Masthead;
